import React, { useState } from 'react';
// import moment from 'moment-timezone';


export function Top10ByLatestAll() {

    const [listRecentNews, setListRecentNews] = useState([{}])

    React.useEffect(() => {
        getRecentNews()
    }, [])

    async function getRecentNews() {
        const res = await fetch("/api/news/getRecentNews")
        const data = await res.json()
        setListRecentNews(data.dataenum)
    }

    return (
        <div>
            <div className='head-topic pb-2 mb-2'>
                <a href="#nolink" className='notLink' onClick={ e => e.preventDefault() }>ข่าวล่าสุด</a>
            </div>
            <div className="row row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-4 row-cols-xl-5">
                {listRecentNews.map(item => (
                    <div className="col-mb-4  mb-3 item-show" key={Number(item.newsId)}>
                        <a href={`/news?nid=${item.newsId}`} target="_parent" rel="noreferrer" >

                            <div className="card" style={{ height: "100%" }}>
                                <div className='card-img' dangerouslySetInnerHTML={{ __html: item.thumbnail }}></div> {/* thumbnail */}
                                <div className="card-body">

                                    <div className="card-text card-text-set">
                                        {/* info */}
                                        <div className='card-text-info'>
                                            {item.category}
                                        </div>
                                        <div className='card-text-date'>
                                            {/* {moment(new Date(item.createDate)).format("DD-MM-YYYY HH:mm")} น. */}
                                            {item.createDateSt}
                                        </div>
                                    </div>
                                    <div className="card-title" >
                                        <h2>
                                            <div className='a_title'>
                                                {item.title}
                                            </div>
                                        </h2>
                                    </div>
                                </div>

                            </div>
                        </a>

                    </div>
                ))}
            </div>
        </div>
    )
}