// import { Top10ByLatestAll } from './HomePage/Top10ByLatestAll';
import { Editor } from '../Utils/CKEditor.js';

export function MainPost() {
    return (
        <>
            {/* Title */}
            {/* ประเภทข่าว */}
            {/* Thumbnail */}
            {/* Tags */}
            {/* CKEditor */}
            <Editor />
            {/* POST SEND */}
        </>
    );
}
