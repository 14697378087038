import React, { useEffect } from 'react';
import { NavBar } from "../containers/NavBar";
import { Content } from "../containers/Home";
import { Footer } from "../containers/Footer";
import { SaveLog } from "../components/Utils/SaveLog";

import ScrollButton from "../components/Utils/ScrollButton";



export function Home() {
    useEffect(() => {
        document.title = 'Siambanterng - สยามบันเทิง'
        document.getElementById('menu-home').style.color = "#cc230b"
        SaveLog(0,0,'landing')
    }, []);
    
    return (
        <>
            <NavBar />
            <Content />
            <Footer />
            <ScrollButton />
        </>
    );
}