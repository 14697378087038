import { SlidesShow } from '../components/Content/SlidesShow';
import { MainContent } from '../components/Content/MainContent';
import { SubContent } from '../components/Content/SubContent';

export function Content() {
    return (
        <>
            <div>
                <SlidesShow />
            </div>
            <div className='container'>
                <div className='row'>
                    {/* <div className='col-9' style={{ borderColor: "red",borderStyle: "solid" }}> */}
                    <div className='col-lg-10 col-md-12 ' style={{  }}>
                        <MainContent />
                    </div>
                    {/* <div className='col-3' style={{ borderColor: "green",borderStyle: "solid" }}> */}
                    <div className='col-lg-2 col-md-12' style={{  }}>
                        <SubContent />
                    </div>
                </div>
            </div>
        </>

    )
}