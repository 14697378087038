import logo from '../../images/logo-web.png'
import * as Icon from 'react-bootstrap-icons';
import React, { useState } from 'react';

export function Detail() {

    const [iconFacebook, setIconFacebook] = useState(false);
    const [iconIG, setIconIG] = useState(false);
    const [iconTwitter, setIconTwitter] = useState(false);
    const [iconTikTok, setIconTikTok] = useState(false);
    const [navGroup, setNavGroup] = useState([{ "groupId": 0, "groupName": "xxx", "categories": [{ "categoryId": 0, "categoryName": "xxx" }] }])
    const [webView, settWebView] = useState(0);

    React.useEffect(() => {
        getData()
        getWebView()
    }, [])

    async function getData() {
        const res = await fetch("/api/news/getGroupAndCates")
        const data = await res.json()
        setNavGroup(data.dataenum)
    }

    async function getWebView() {
        const res = await fetch("/api/news/getWebViews")
        const data = await res.json()
        settWebView(data.dataenum)
    }

    function numberWithCommas(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return (
        <div className="mx-auto py-3 flex-column" style={{ width: "90%" }}>
            <div className="d-flex justify-content-between flex-wrap">
                <div className='d-lg-none' style={{ width: "100%" }}>
                    {/* Mobile */}
                    <div className='row'>
                        <div className='col-4 d-none d-sm-block' style={{ display: 'grid', alignItems: 'end' }}>
                            <a href="/">
                                <img alt="logo" src={logo} width="130px" />
                            </a>
                        </div>
                        <div className='col-12 col-sm-8'>
                                Siambanterng.com <br />
                                เว็บไซต์ข่าวบันเทิง ที่สดใหม่และก่อนใคร
                                อีเมล <span style={{fontSize:'14px'}}>siambanterng2020@hotmail.com,<br />chicbanterng2022@gmail.com</span> หรือ ช่องทางออนไลน์
                        </div>
                    </div>
                    <div className="d-flex justify-content-around mt-4 mb-4">
                        <a href="https://www.facebook.com/profile.php?id=100088518548474" target="_blank" rel="noreferrer">
                            <Icon.Facebook color={iconFacebook ? "dodgerblue" : "dark"} size={25}
                                onMouseOver={() => setIconFacebook(true)}
                                onMouseLeave={() => setIconFacebook(false)}
                            />
                        </a>
                        <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
                            <Icon.Instagram color={iconIG ? "deeppink" : "dark"} size={25}
                                onMouseOver={() => setIconIG(true)}
                                onMouseLeave={() => setIconIG(false)}
                            />
                        </a>
                        <a href="https://twitter.com/siambanterng1" target="_blank" rel="noreferrer">
                            <Icon.Twitter color={iconTwitter ? "dodgerblue" : "dark"} size={25}
                                onMouseOver={() => setIconTwitter(true)}
                                onMouseLeave={() => setIconTwitter(false)}
                            />
                        </a>
                        <a href="https://www.tiktok.com" target="_blank" rel="noreferrer">
                            <Icon.Tiktok color={iconTikTok ? "deeppink" : "dark"} size={25}
                                onMouseOver={() => setIconTikTok(true)}
                                onMouseLeave={() => setIconTikTok(false)}
                            />
                        </a>
                    </div>
                </div>

                <div className='d-none d-lg-block'>
                    {/* Desktop */}
                    <a href="/" className="d-flex align-items-center p-0 text-dark">
                        <img alt="logo" src={logo} width="130px" />
                    </a>
                    <p className="my-3" style={{ width: '250px' }}>
                        Siambanterng.com <br />
                        เว็บไซต์ข่าวบันเทิง ที่สดใหม่และก่อนใคร
                        อีเมล <span style={{fontSize:'14px'}}>siambanterng2020@hotmail.com,<br />chicbanterng2022@gmail.com</span> หรือ ช่องทางออนไลน์
                    </p>
                    <p>สถิติการเยี่ยมชม : {numberWithCommas(webView)} ครั้ง</p>
                    <div className="d-flex justify-content-around mt-4 mb-4">

                        <a href="https://www.facebook.com/profile.php?id=100088518548474" target="_blank" rel="noreferrer">
                            <Icon.Facebook color={iconFacebook ? "dodgerblue" : "dark"} size={25}
                                onMouseOver={() => setIconFacebook(true)}
                                onMouseLeave={() => setIconFacebook(false)}
                            />
                        </a>
                        <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
                            <Icon.Instagram color={iconIG ? "deeppink" : "dark"} size={25}
                                onMouseOver={() => setIconIG(true)}
                                onMouseLeave={() => setIconIG(false)}
                            />
                        </a>
                        <a href="https://twitter.com/siambanterng1" target="_blank" rel="noreferrer">
                            <Icon.Twitter color={iconTwitter ? "dodgerblue" : "dark"} size={25}
                                onMouseOver={() => setIconTwitter(true)}
                                onMouseLeave={() => setIconTwitter(false)}
                            />
                        </a>
                        <a href="https://www.tiktok.com" target="_blank" rel="noreferrer">
                            <Icon.Tiktok color={iconTikTok ? "deeppink" : "dark"} size={25}
                                onMouseOver={() => setIconTikTok(true)}
                                onMouseLeave={() => setIconTikTok(false)}
                            />
                        </a>
                    </div>
                </div>

                {navGroup.map(item => (
                    <div className='footer-detail d-none d-lg-block' key={String(item.groupId)}>
                        <p className="h5 mb-4 footer-detail-topic" style={{ fontWeight: '600' }}>
                            {item.groupName}
                        </p>
                        <div className='href' flex="column" style={{ cursor: 'pointer', padding: '0' }}>
                            {item.categories.map((cate, i) => (
                                <div key={String(cate.categoryId)}>
                                    <a href={`category/list?cname=${cate.categoryName}&cid=${cate.categoryId}&page=1`}>
                                        {cate.categoryName}
                                    </a>
                                    <br />
                                </div>

                            ))}
                        </div>
                    </div>
                ))}


            </div>
        </div>
    )
}


