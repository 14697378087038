import { NewsEditor } from '../components/Admin/NewsEditor';

export function AdminNewsEditor() {
    return (
        <>

            <NewsEditor />

        </>

    )
}