
import { Animation404 } from '../components/Content/Animation404';
import { SubContent } from '../components/Content/SubContent';

export function Page404() {
    return (
        <>
            <hr />
            <div className='container'>
                <div className='row'>
                    {/* <div className='col-9' style={{ borderColor: "red",borderStyle: "solid" }}> */}
                    <div className='col-lg-10 col-md-12 ' style={{  }}>
                        <Animation404 />
                    </div>
                    {/* <div className='col-3' style={{ borderColor: "green",borderStyle: "solid" }}> */}
                    <div className='col-lg-2 col-md-12' style={{  }}>
                        <SubContent />
                    </div>
                </div>
            </div>
        </>

    )
}