
import Carousel from 'react-bootstrap/Carousel';
import React, { useState } from 'react';


export function SlidesShow() {

    const [listSlidesShow, setListSlidesShow] = useState([{}])

    React.useEffect(() => {
        getSlidesShow()
    }, [])


    async function getSlidesShow() {
        await fetch("/api/news/getTop10News")
            .then(res => res.json())
            .then(async (data) => {
                let listTemp = []
                for (let index = 0; index < data['dataenum'].length; index++) {
                    let imgBase64 = String(data['dataenum'][index]['thumbnail'])
                    let indexStartImg = imgBase64.search(`src="`) + 5;
                    let indexStopImg = imgBase64.search(`"><figcaption>`);
                    if (indexStopImg === -1) {
                        indexStopImg = imgBase64.search(`"></`);
                    }

                    listTemp.push({
                        src: imgBase64.slice(indexStartImg, indexStopImg),
                        altText: data['dataenum'][index]['title'],
                        caption: data['dataenum'][index]['category'],
                        key: data['dataenum'][index]["newsId"],
                    })
                }
                await setListSlidesShow(listTemp)
                // console.log(listTemp)
            }
            );
    }

    return (
        <>
            <div className='TopMain'>
                <div className=' container'>
                    ยอดนิยม
                </div>
            </div>

            {/* <div className='d-md-none'>
                <Carousel style={{ backgroundColor: "black" }}>
                    {listSlidesShow.map((item, index) => (
                        <Carousel.Item interval={1500} key={Number(index)}>
                            <a href={'/news?nid=' + item.key} target="_blank" rel="noreferrer" >
                                <img
                                    className="d-block w-100c"
                                    src={(item.src)}
                                    alt={item.caption}
                                    width="100%"
                                    height="400vh"

                                />
                                <Carousel.Caption className='showtext-slides'>
                                    <h3>{item.altText}</h3>
                                    <p>{item.caption}</p>
                                </Carousel.Caption>
                            </a>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
            <div className='d-none d-md-block'>
                <Carousel style={{ backgroundColor: "black" }}>
                    {listSlidesShow.map((item, index) => (
                        <Carousel.Item interval={1500} key={Number(index)}>
                            <a href={'/news?nid=' + item.key} target="_blank" rel="noreferrer" >

                                <div style={{ display: "inline-flex" }}>
                                    <div>
                                        <img
                                            className="d-block w-100c"
                                            src={(item.src)}
                                            alt={item.caption}
                                            width="100%"
                                            height="400vh"
                                            style={{ width: "50vw" }}
                                        />
                                    </div>
                                    <div className='mobile-Caption m-4'>
                                        <p className='textinslides'>{item.altText}</p>
                                        <p style={{ fontSize: "1rem" }}>{item.caption}</p>
                                    </div>
                                </div>
                            </a>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
            <div className='TopMain' style={{ lineHeight: "0.5em" }}>
                &nbsp;
            </div> */}



            <div className='d-none d-md-block'>
                {/* Desktop */}
                <Carousel style={{ height: "50vh" }}>
                    {listSlidesShow.map((item, index) => (
                        <Carousel.Item interval={1500} key={Number(index)}>
                            <a href={'/news?nid=' + item.key} target="_blank" rel="noreferrer"
                                style={{
                                    display: 'flex'
                                }}
                            >
                                <img
                                    className="d-block w-100"
                                    src={(item.src)}
                                    alt={item.caption}
                                    style={{
                                        // height: "40vh",
                                        width: "100%",
                                        height: "50vh",
                                        maxWidth: '50%'
                                    }}
                                />
                                <div
                                    className='sdbarText'
                                    style={{
                                        display: 'grid',
                                        backgroundColor: 'black',
                                        alignItems: 'center',
                                        justifyItems: 'center',
                                        alignContent: 'space-evenly',
                                        width: '100vw',
                                        height: "50vh",
                                        maxWidth: '50%'
                                    }}
                                >
                                    <h3 className='textinslides'>{item.altText}</h3>
                                    <p style={{ fontSize: '1.5vw' }}>{item.caption}</p>
                                </div>



                            </a>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>

            <div className='d-md-none'>
                {/* Mobile */}
                <Carousel style={{ height: "auto" }} className='carousel-indicators-mobile'>
                    {listSlidesShow.map((item, index) => (
                        <Carousel.Item interval={1500} key={Number(index)}>
                            <a href={'/news?nid=' + item.key} target="_blank" rel="noreferrer"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <img
                                    className="d-block w-100"
                                    src={(item.src)}
                                    alt={item.caption}
                                    style={{
                                        // height: "40vh",
                                        width: "100%",
                                        height: "35vh",
                                        maxWidth: '100%'
                                    }}
                                />
                                <div
                                    className='sdbarText'
                                    style={{
                                        display: 'grid',
                                        backgroundColor: 'black',
                                        alignItems: 'center',
                                        justifyItems: 'center',
                                        alignContent: 'center',
                                        width: '100vw',
                                        height: "15vh",
                                        maxWidth: '100%'
                                    }}
                                >
                                    <h3 className='textinslides'>{item.altText}</h3>
                                    <p style={{ fontSize: '2.5vw' }}>{item.caption}</p>
                                </div>



                            </a>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
            <div className='TopMain' style={{ lineHeight: "0.5em" }}>
                &nbsp;
            </div>
        </>
    );
}