import { Top10ByLatestAll } from './HomePage/Top10ByLatestAll';
import { Top10ByLatestGroup } from './HomePage/Top10ByLatestGroup';
import { Top10ByLatestTags } from './HomePage/Top10ByLatestTags';

export function MainContent() {
    return (
        <>
            < Top10ByLatestAll />
            < Top10ByLatestGroup />
            < Top10ByLatestTags />
        </>
    );
}
