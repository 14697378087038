import { Detail } from '../components/Footer/Detail';
import { Copyright } from '../components/Footer/Copyright';


export function Footer() {
    return (
        <footer className="shadow">
            <Detail />
            <Copyright />
        </footer>
    )
}