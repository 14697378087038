
import React, { useState } from 'react';

export function Top10ByLatestGroup() {

    const [listGroupAndNews, setListGroupAndNews] = useState([{}])

    React.useEffect(() => {
        getGroup()
    }, [])

    async function getGroup() {
        await fetch("/api/news/getMGroup")
            .then(res => res.json())
            .then(async (data) => {
                data = data.dataenum
                let listTemp = []
                for (let index = 0; index < data.length; index++) {
                    let GroupID = data[index]['groupId']
                    let formData = new FormData();
                    formData.append('id', Number(GroupID));
                    await fetch('/api/news/getRecentNewsByGroup', {
                        method: 'POST',
                        body: formData,
                    }).then(res_news => res_news.json()).then(async (data_news) => {
                        await listTemp.push({
                            GroupID: GroupID,
                            GroupName: data[index]['groupName'],
                            News: data_news['dataenum']
                        })
                    });
                }
                await setListGroupAndNews(listTemp)
            }
            );
    }

    return (
        <div className='p-2'>
            {listGroupAndNews.map(item => (
                <div key={Number(item.GroupID)}>
                    <div className='head-topic pb-2 mb-2'>
                        <a href={`group/list?gname=${item.GroupName}&gid=${item.GroupID}&page=1`} target="_blank" rel="noreferrer" >{item.GroupName}<span style={{marginLeft:'0.7rem',fontSize: '1rem'}}>(ดูทั้งหมด)</span></a>
                    </div>
                    <div>
                        {
                            (item.News || []).length === 0
                                ? <div className='NoContent'>
                                    ไม่มีข้อมูล
                                </div>
                                : <div className="row row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-4 row-cols-xl-5">
                                    {item.News.map(itemNew => (
                                        <div className="col mb-4  mb-3 item-show" key={Number(itemNew.newsId)}>
                                            <a href={`/news?nid=${itemNew.newsId}`} target="_parent" rel="noreferrer" >
                                                <div className="card" style={{ height: "100%" }}>
                                                    <div className='card-img' dangerouslySetInnerHTML={{ __html: itemNew.thumbnail }}></div> {/* thumbnail */}
                                                    <div className="card-body">
                                                        <div className="card-text card-text-set">
                                                            {/* info */}
                                                            <div className='card-text-info' >
                                                                {itemNew.category}
                                                            </div>
                                                            <div className='card-text-date'>
                                                                {/* {moment(new Date(item_detail.createDate)).format("DD-MM-YYYY HH:mm")} น. */}
                                                                {itemNew.createDateSt}
                                                            </div>
                                                        </div>
                                                        <div className="card-title" >
                                                            <h2>
                                                                <div className='a_title'>
                                                                    {itemNew.title}
                                                                </div>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    ))}
                                </div>
                        }
                    </div>
                </div>
            ))}

        </div>
    )
}