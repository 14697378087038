import React, { useEffect } from 'react';
import { NavBar } from "../containers/NavBar";
import { Page404 } from "../containers/Page404";
import { Footer } from "../containers/Footer";
import { SaveLog } from "../components/Utils/SaveLog";

import ScrollButton from "../components/Utils/ScrollButton";



export function Error404(props) {
    useEffect(() => {
        // document.title = 'Siambanterng | หน้าหลัก'
        document.getElementById('menu-home').style.color = "#cc230b"
        SaveLog(0,0,'landing')
    }, []);
    
    return (
        <>
            <NavBar />
            <Page404 />
            <Footer />
            <ScrollButton />
        </>
    );
}