import React, { useEffect } from 'react';
import { NavBar } from "../containers/NavBar";
import { PostNewsEditor } from "../containers/PostNewsEditor";
import { Footer } from "../containers/Footer";
import ScrollButton from "../components/Utils/ScrollButton";



export function PostNews() {
    useEffect(() => {
        document.title = 'Siambanterng | โพสต์ข่าว';
        // document.getElementById('menu-0').style.color = "#cc230b"
    }, []);
    return (
        <>
            <NavBar />
            <PostNewsEditor />
            <Footer />
            <ScrollButton />
        </>
    );
}