// import React, { useEffect, useState } from 'react';
import React from 'react';
import { NavBar } from "../containers/NavBar";
import { Content } from "../containers/News";
import { Footer } from "../containers/Footer";
import ScrollButton from "../components/Utils/ScrollButton";
// import axios from 'axios';
// import { useSearchParams } from "react-router-dom";


export function News() {
    // useEffect(() => {
    //     document.title = 'Siambanterng | ข่าว';
    //     // document.getElementById('menu-0').style.color = "#cc230b"
    // }, []);

    // const [searchParams] = useSearchParams();
    // const [GetGroupID, setGetGroupID] = useState(false)

    // useEffect(() => {
    //     async function SET_PAGE_ALL() {
    //         let formData = new FormData();
    //         formData.append('id', Number(searchParams.get('nid')));

    //         // GET API =================================================
    //         await axios({
    //             method: "post",
    //             url: "/api/news/getNewsById",
    //             data: formData,
    //             headers: { "Content-Type": "multipart/form-data" },
    //         })
    //         .then(function (response) {
    //             console.log(response.data.dataenum)
    //             setGetGroupID(response.data.dataenum.groupId)
    //         })
    //         .catch(function (response) {
    //             console.log(response);
    //         });
    //         // =========================================================
    //     }
        
    //     SET_PAGE_ALL();
    // }, [searchParams]);

    // useEffect(() => {
    //     async function SET_META() {
    //         // Set ColorMenu, Title and META
    //         // =======================================================
    //         document.getElementById(`menu-${GetGroupID}`).style.color = "#cc230b"
    //         // =======================================================
    //     }
    //     SET_META()
    // },[GetGroupID]);


    return (
        <>
            <NavBar />
            <Content />
            <Footer />
            <ScrollButton />
        </>
    );
}