import React, { useState, useEffect, useCallback } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
// import { useFormik } from "formik";
// import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';
import { useSearchParams } from "react-router-dom";
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import axios from "axios";


export function NewsEditor() {

    const MySwal = withReactContent(Swal)

    // Set State
    const [searchParams] = useSearchParams(); //Number(searchParams.get('nid'))
    const [mGroup, setMGroup] = useState(null);
    const [mCategory, setMCategory] = useState(null);
    const [getEditor, setEditor] = useState(null);
    const [editorData, setEditorData] = useState("");
    const [files, setFiles] = useState(false);


    const [newsId, setnewsId] = useState(false);
    const [title, settitle] = useState(false);
    const [getDataCKEditor, setDataCKEditor] = useState("");
    const [categoryId, setcategoryId] = useState(false);
    const [groupId, setgroupId] = useState(false);
    const [tags, settags] = useState('');
    const [status, setstatus] = useState(1);

    const onDrop = useCallback(acceptedFiles => {

        // Loop through the accepted files
        acceptedFiles.forEach(file => {
            const formData = new FormData();
            formData.append('upload', file);

            // Use fetch to upload the file to the server
            axios({
                method: "post",
                url: 'api/v2/upload',
                data: formData,
                headers: {
                    'X-CSRF-TOKEN': 'CSFR-Token',
                    Authorization: 'Bearer <JSON Web Token>'
                },
            })
                .then(function (response) {
                    //handle success
                    console.log(response.data)
                    if (response.data.uploaded === true) {
                        setFiles(`<figure class="image"><img src="${response.data.url}"></figure>`)
                    }
                })
                .catch(function (response) {
                    //handle error
                    console.log(response);
                });
        });
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });



    // Loader group and cat
    useEffect(() => {
        async function getMGroup() {
            const res = await fetch("/api/admin/getMGroup")
            const data = await res.json()
            setMGroup(data.dataenum)

        }
        getMGroup()

        async function getMCategory() {
            const res = await fetch("/api/admin/getMCategory")
            const data = await res.json()
            setMCategory(data.dataenum)
            // console.log(data.dataenum)

        }
        getMCategory()
    }, [])

    useEffect(() => {
        // Get info data news
        setnewsId(Number(searchParams.get('nid')))
        async function getNewsById(value) {
            let formData = new FormData()
            formData.append('nid', Number(value))

            let res = await fetch("/api/admin/getNewsById", {
                method: 'POST',
                // headers: { 'Content-type': 'application/json' },
                body: formData
            });
            let resJson = await res.json();
            if (resJson.status === 1) {
                setnewsId(Number(searchParams.get('nid')))
                settitle(resJson.dataenum.title)
                setcategoryId(resJson.dataenum.categoryId)
                setgroupId(resJson.dataenum.groupId)
                setEditorData(resJson.dataenum.content)
                setFiles(resJson.dataenum.thumbnail)
                settags(resJson.dataenum.tags)
                setstatus(resJson.dataenum.status)
            }
        }
        if (Number(searchParams.get('nid')) !== 0) {
            getNewsById(Number(searchParams.get('nid')))
        }


    }, [searchParams])


    const formFileOnSubmit = async (values, actions) => {
        // console.log(values);

        try {
            let res = await fetch("/api/admin/SaveNews", {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                    'newsId': newsId,
                    'title': title,
                    'content': getDataCKEditor,
                    'categoryId': categoryId,
                    'groupId': groupId,
                    'thumbnail': files,
                    'tags': tags,
                    'status': status,
                })
            });
            let resJson = await res.json();
            // console.log(resJson);
            if (resJson.status === 1) {
                MySwal.fire({
                    title: <strong>{resJson.message}</strong>,
                    icon: 'success',
                    showConfirmButton: true,
                    timer: 2500
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace('/admin/manageNews')
                        // window.location.reload();
                    }

                })
            } else {
                MySwal.fire({
                    title: <strong>{resJson.message}</strong>,
                    icon: 'error',
                    showConfirmButton: true,
                }).then((result) => {
                    // if (result.isConfirmed) {
                    //     window.location.replace('/admin/manageNews')

                    // }
                })
            }
        } catch (err) {
            console.log(err);
            MySwal.fire({
                title: <strong>เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง</strong>,
                icon: 'error',
                showConfirmButton: true,
            }).then((result) => {
                // if (result.isConfirmed) {
                //     window.location.replace('/admin/manageNews')

                // }
            })
        }

    }

    return (
        <div className='pt-2 pb-5 container'>
            <div>
                <h1 className='py-2'>เพิ่ม/แก้ไขข่าว</h1>
            </div>

            <hr className='mb-4' />
            <form>
                <div className="form-group mb-4" style={{ display: "none" }}>
                    <label htmlFor="newsId">ไอดี (* จำเป็น)</label>
                    <input type="text" value={newsId} className="form-control" id="newsId" aria-describedby="newsId" onChange={e => setnewsId(e.target.value)} />
                </div>
                <div className="form-group mb-4">
                    <label htmlFor="groupId">กลุ่ม (* จำเป็น)</label>
                    <select id="groupId" className="form-control" required value={groupId} onChange={e => setgroupId(e.target.value)}>
                        <option value={0} hidden>โปรดเลือกกลุ่ม</option>
                        {mGroup?.map(item => (
                            <option
                                key={Number(item.groupId)}
                                value={item.groupId}
                            >
                                {item.groupName}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-group mb-4">
                    <label htmlFor="categoryId">หมวดหมู่ (* จำเป็น)</label>
                    <select id="categoryId" className="form-control" required disabled={groupId === false ? true : false} value={categoryId} onChange={e => setcategoryId(e.target.value)}>
                        <option value={0} hidden>โปรดเลือกหมวดหมู่</option>
                        {mCategory?.map(item => (
                            Number(item.groupId) === Number(groupId)
                                ? (
                                    <option
                                        key={Number(item.categoryId)}
                                        value={item.categoryId}
                                    >
                                        {item.categoryName}
                                    </option>
                                )
                                : null
                        ))}
                    </select>
                </div>
                <div className="form-group mb-4">
                    <label htmlFor="title">หัวข้อ (* จำเป็น)</label>
                    <input type="text" value={title === false ? "" : title} disabled={categoryId === false ? true : false} placeholder='โปรดกรอกหัวข้อ' required className="form-control" id="title" aria-describedby="title" onChange={e => settitle(e.target.value)} />
                </div>
                <div className="form-group mb-4">
                    <label >เนื้อข่าว (* จำเป็น)</label>
                    <CKEditor
                        data={editorData}
                        disabled={title === false ? true : false}
                        onReady={editor => {
                            editor.ui.getEditableElement().parentElement.insertBefore(
                                editor.ui.view.toolbar.element,
                                editor.ui.getEditableElement()
                            );
                            setEditor(editor);
                            setDataCKEditor(editor.getData())
                        }}
                        onError={(error, { willEditorRestart }) => {
                            if (willEditorRestart) {
                                getEditor.ui.view.toolbar.element.remove();
                            }
                        }}
                        onChange={(event, editor) => {
                            // const data = editor.getData();
                            // console.log({ event, editor, data });
                            // console.log("TEST");
                            setDataCKEditor(editor.getData())
                        }}
                        onBlur={(event, editor) => {
                            // console.log('Blur.', editor);
                            setDataCKEditor(editor.getData())
                        }}
                        onFocus={(event, editor) => {
                            // console.log('Focus.', editor);
                            setDataCKEditor(editor.getData())
                        }}
                        editor={DecoupledEditor}
                        // data=""
                        config={{
                            ckfinder: {
                                uploadUrl: 'api/v2/upload', // Proxy config
                                withCredentials: true,
                                headers: {
                                    'X-CSRF-TOKEN': 'CSFR-Token',
                                    Authorization: 'Bearer <JSON Web Token>'
                                }
                            },
                            images: {
                                resizeOptions: [
                                    {
                                        name: 'resizeImage:original',
                                        value: null,
                                        label: 'Original'
                                    },
                                    {
                                        name: 'resizeImage:40',
                                        value: '40',
                                        label: '40%'
                                    },
                                    {
                                        name: 'resizeImage:60',
                                        value: '60',
                                        label: '60%'
                                    }
                                ],
                                toolbar: ['resizeImage']
                            },
                            language: 'th',
                            fontSize: {
                                options: [
                                    9,
                                    11,
                                    13,
                                    'default',
                                    17,
                                    19,
                                    21
                                ]
                            },
                            fontFamily: {
                                options: [
                                    'default',
                                    'Kanit', 'Prompt',
                                    'Arial, Helvetica, sans-serif',
                                    'Courier New, Courier, monospace',
                                    'Georgia, serif',
                                    'Lucida Sans Unicode, Lucida Grande, sans-serif',
                                    'Tahoma, Geneva, sans-serif',
                                    'Times New Roman, Times, serif',
                                    'Trebuchet MS, Helvetica, sans-serif',
                                    'Verdana, Geneva, sans-serif',
                                    'Ubuntu, Arial, sans-serif',
                                    'Ubuntu Mono, Courier New, Courier, monospace',
                                ]
                            },
                            mediaEmbed: {
                                previewsInData: true
                            },
                        }}
                    />
                </div>
                <div className="form-group mb-4">
                    <label htmlFor="thumbnail">รูปปกข่าว (* จำเป็น)</label>
                    <input style={{ display: "none" }} type="text" required value={files} className="form-control" id="thumbnail" aria-describedby="thumbnail" onChange={e => setFiles(e.target.value)} />
                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                                <p className="dropzone-active">ลากหรือวางไฟล์ที่นี่ หรือคลิกเพื่อเลือกไฟล์ ...</p> :
                                <p className="dropzone-inactive">ลากหรือวางไฟล์ที่นี่ หรือคลิกเพื่อเลือกไฟล์ ...</p>
                        }
                        <div>
                            {files !== false &&
                                <div dangerouslySetInnerHTML={{ __html: files }}></div>
                            }
                        </div>
                    </div>
                </div>
                <div className="form-group mb-4">
                    <label htmlFor="tags">Tag #หากมีมากว่า 1 Tag ให้คั่นด้วย (,) Ex ร้านกาแฟ,ร้านเค้ก</label>
                    <input value={tags === false ? "" : tags} disabled={title === false ? true : false} type="tags" name='password' className="form-control" id="tags" aria-describedby="tags" onChange={e => settags(e.target.value)} />
                </div>
                <div className="form-group mb-4">
                    <label htmlFor="status">สถานะ (* จำเป็น)</label>
                    <select id="status" className="form-control" required value={status} disabled={title === false ? true : false} onChange={e => setstatus(e.target.value)}>
                        <option value={1} >
                            เผยแพร่
                        </option>
                        <option value={2}>
                            ไม่เผยแพร่
                        </option>
                    </select>
                </div>

                {/* <button type="button" className="btn btn-primary" onClick={formFormik.handleSubmit}>บันทึก</button> */}
                <hr className='mb-4' />
                <button type="button" className="btn btn-success" onClick={formFileOnSubmit}> บันทึก </button>
                <Link to='/admin/manageNews'>
                    <Button style={{ float: "right" }} color={"outline-danger"}>
                        ย้อนกลับ
                    </Button>
                </Link>
            </form>
        </div>
    )
}