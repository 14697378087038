import { ManageNews } from '../components/Admin/ManageNews';

export function AdminManageNews() {
    return (
        <>

            <ManageNews />

        </>

    )
}