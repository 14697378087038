import React, { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';

const ScrollButton = () => {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <div className='button-to-top' style={{cursor:'default', width: 'auto', right: '0px'}}>
            {/* <button onClick={scrollToTop} style={{ 
                    display: visible ? 'inline' : 'none' , 
                    color:"#CB240B", 
                    backgroundColor: "white",
                    borderRadius: "100%"
            }}>Δ</button> */}
            {/* <button type="button" onClick={scrollToTop} className="btn btn-danger rounded-circle" style={{ 
                    display: visible ? 'inline' : 'none' , fontWeight:"bold"
            }}>↑</button> */}
            <Icon.ArrowUpCircleFill onClick={scrollToTop} size={30}
                style={{ 
                    display: visible ? 'inline' : 'none' , 
                    color:"#CB240B", 
                    backgroundColor: "white",
                    borderRadius: "100%",
                    cursor:'pointer'
            }}
            />
        </div>
    );
}

export default ScrollButton;