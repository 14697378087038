import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from "axios";
// =========================================================================
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
// =========================================================================

export function Editor() {
    const [getEditor, setEditor] = useState(null);
    const [getDataCKEditor, setDataCKEditor] = useState("");
    function getText() {
        console.log(getDataCKEditor)
        document.getElementById('result').innerText = getDataCKEditor
    }


    function getHTML() {
        console.log(getDataCKEditor)
        document.getElementById('result').innerHTML = getDataCKEditor
    }

    // Sorawit 2566/01/26 Add upload images
    const [files, setFiles] = useState(false);
    const onDrop = useCallback(acceptedFiles => {

        // Loop through the accepted files
        acceptedFiles.forEach(file => {
            const formData = new FormData();
            formData.append('upload', file);

            // Use fetch to upload the file to the server
            axios({
                method: "post",
                url: 'api/v2/upload',
                data: formData,
                headers: {
                    'X-CSRF-TOKEN': 'CSFR-Token',
                    Authorization: 'Bearer <JSON Web Token>'
                },
            })
            .then(function (response) {
                //handle success
                console.log(response.data)
                if (response.data.uploaded === true){
                    setFiles(response.data.url)
                }
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
        });
    }, []);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({ onDrop });


    
    return (
        <div className="App">
            <h2>CKEditor 5</h2>
            <CKEditor
                onReady={editor => {
                    editor.ui.getEditableElement().parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.getEditableElement()
                    );
                    setEditor(editor);
                    setDataCKEditor(editor.getData())
                }}
                onError={(error, { willEditorRestart }) => {
                    if (willEditorRestart) {
                        getEditor.ui.view.toolbar.element.remove();
                    }
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    console.log({ event, editor, data });
                    console.log("TEST");
                    setDataCKEditor(editor.getData())
                }}
                onBlur={(event, editor) => {
                    // console.log('Blur.', editor);
                    setDataCKEditor(editor.getData())
                }}
                onFocus={(event, editor) => {
                    // console.log('Focus.', editor);
                    setDataCKEditor(editor.getData())
                }}
                editor={DecoupledEditor}
                // data=""
                config={{
                    ckfinder: {
                        // uploadUrl: 'http://127.0.0.1:30249/upload',
                        uploadUrl: 'api/v2/upload', // Proxy config
                        withCredentials: true,
                        headers: {
                            'X-CSRF-TOKEN': 'CSFR-Token',
                            Authorization: 'Bearer <JSON Web Token>'
                        }
                    },
                    images:{
                        resizeOptions: [
                            {
                                name: 'resizeImage:original',
                                value: null,
                                label: 'Original'
                            },
                            {
                                name: 'resizeImage:40',
                                value: '40',
                                label: '40%'
                            },
                            {
                                name: 'resizeImage:60',
                                value: '60',
                                label: '60%'
                            }
                        ],
                        toolbar: ['resizeImage' ]
                    },
                    language: 'th',
                    fontSize: {
                        options: [
                            9,
                            11,
                            13,
                            'default',
                            17,
                            19,
                            21
                        ]
                    },
                    fontFamily: {
                        options: [
                            'default',
                            'Kanit', 'Prompt',
                            'Arial, Helvetica, sans-serif',
                            'Courier New, Courier, monospace',
                            'Georgia, serif',
                            'Lucida Sans Unicode, Lucida Grande, sans-serif',
                            'Tahoma, Geneva, sans-serif',
                            'Times New Roman, Times, serif',
                            'Trebuchet MS, Helvetica, sans-serif',
                            'Verdana, Geneva, sans-serif',
                            'Ubuntu, Arial, sans-serif',
                            'Ubuntu Mono, Courier New, Courier, monospace',
                        ]
                    },
                    mediaEmbed: {
                        previewsInData: true
                    },
                }}
            />

            <hr />
            <br />
            <br />
            <br />
            <h2>Result</h2>
            <button onClick={getHTML} className='btn btn-primary m-1'>Preview</button>
            <button onClick={getText} className='btn btn-success m-1'>GET TEXT</button>
            <hr />
            <div className='row'>
                <div id='result' className="ck ck-content" lang="th" dir="ltr" role="textbox">
                    <h3>With result . . .</h3>
                </div>
            </div>

            {/* ------- */}

            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                    <p className="dropzone-active">Drop the files here ...</p> :
                    <p className="dropzone-inactive">Drag 'n' drop some files here, or click to select files</p>
                }
                <div>
                    {files !== false &&
                        <img src={files} alt={files} />
                    }
                </div>
            </div>

            {/* ------- */}

            
        </div>
    );
}
