// import { Detail } from '../components/Footer/Detail';
// import { Copyright } from '../components/Footer/Copyright';
import { MainPost } from '../components/PostNews/MainPost';
import { SubContent } from '../components/Content/SubContent';

export function PostNewsEditor() {
    return (
        <>
            <div>
                {/* <SlidesShow /> */}
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-10 col-md-12 ' style={{  }}>
                        <MainPost />
                    </div>
                    <div className='col-lg-2 col-md-12' style={{  }}>
                        <SubContent />
                    </div>
                </div>
            </div>
        </>
    )
}