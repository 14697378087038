import React from 'react';
import { NavBar } from "../containers/NavBar";
import { Footer } from "../containers/Footer";
import ScrollButton from "../components/Utils/ScrollButton";
import { AdminManageUsers } from "../containers/AdminManageUsers";



export function AdminManageUsersPage() {
    document.title = 'Siambanterng | จัดการผู้ใช้งาน'

    return (
        <>
            <NavBar />
            <AdminManageUsers />
            <Footer />
            <ScrollButton />
        </>
    );
}