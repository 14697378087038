import React, { useEffect } from 'react';
import { NavBar } from "../containers/NavBar";
import { ShowContentList } from "../containers/ShowList";
import { Footer } from "../containers/Footer";
import { SaveLog } from "../components/Utils/SaveLog";

import ScrollButton from "../components/Utils/ScrollButton";



export function ShowList(props) {
    useEffect(() => {
        document.title = 'Siambanterng - สยามบันเทิง'
        document.getElementById('menu-home').style.color = "#cc230b"
        SaveLog(0,0,'landing')
    }, []);
    
    return (
        <>
            <NavBar />
            <ShowContentList prop={props} />
            <Footer />
            <ScrollButton />
        </>
    );
}