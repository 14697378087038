import React, { useState, useEffect } from 'react';
import { Container, Button } from 'reactstrap';
import CreateTable from "./CreateTableAdminComponent";
import { Link } from 'react-router-dom';
import { Animation404 } from '../Content/Animation404';

export function ManageNews() {

    const [adminNews, setAdminNews] = React.useState([])
    const [PageError, setPageError] = useState(false)

    React.useEffect(() => {
        getAdminNews()

    }, [])

    async function getAdminNews() {
        const res = await fetch("/api/admin/getNews")
        const data = await res.json()
        //console.log(data)
        setAdminNews(data.dataenum)

    }

    const adminNewsTbRow = React.useMemo(
        () => adminNews, [adminNews]
    )
    const adminNewsTbColumns = React.useMemo(
        () => [

            {
                Header: '#',
                // accessor: 'no',
                Cell: (row) => {
                    return <div>{Number(row.row.id) + 1}</div>;
                },
            },
            {
                Header: 'กลุ่ม',
                accessor: 'group',
            },
            {
                Header: 'หมวดหมู่',
                accessor: 'category',
            },
            {
                Header: 'หัวข้อข่าว',
                accessor: 'title',
            },
            {
                Header: 'วันที่สร้าง',
                accessor: 'createDateSt',
            },
            {
                Header: 'สถานะ',
                accessor: 'status',
                Cell: ({ value }) => (
                    <div>
                        {value === 1 ? "เผยแพร่" : "ไม่เผยแพร่"}
                    </div>
                )
            },
            {
                Header: 'จัดการ',
                accessor: 'newsId',
                Cell: ({ value }) => (
                    <Link to={'/admin/newsEditor?nid=' + value}>
                        <Button color={"warning"}>
                            แก้ไข
                        </Button>
                    </Link>
                )
            }
        ],
        []
    )


    // Check role
    useEffect(() => {
        const items = JSON.parse(localStorage.getItem('currentUser'))
        if (items !== null && (Number(items['role']) === 1 || Number(items['role']) === 2)) {
            setPageError(false)
        } else {
            setPageError(true)
        }
    }, [])



    return (
        <>
            {PageError === true ? (
                <Animation404 />
            ) : (
                <Container>
                    <h1 className='py-2'>จัดการข่าว</h1>
                    <hr />
                    <Link to='/admin/newsEditor?nid=0'>
                        <Button color={"success"}>
                            เพิ่มข่าว
                        </Button>
                    </Link>
                    <Link to='/'>
                        <Button style={{ float: "right" }} color={"outline-danger"}>
                            ย้อนกลับ
                        </Button>
                    </Link>

                    <br />
                    <br />
                    <CreateTable columns={adminNewsTbColumns} data={adminNewsTbRow} />
                </Container>
            )}
        </>
    )
}