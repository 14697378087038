import React, { useState, useEffect } from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import axios from 'axios';
import { useSearchParams } from "react-router-dom";

export function SubContent() {

    const [listCategory, setListCategory] = useState([{}])

    React.useEffect(() => {
        getCategory()
    }, [])

    async function getCategory() {
        const res = await fetch("/api/news/getMCategory")
        const data = await res.json()
        // console.log(data)
        setListCategory(data.dataenum)
    }

    const [searchParams] = useSearchParams();
    const [GetCategoryId, setGetCategoryId] = useState(false)
    useEffect(() => {
        async function SET_PAGE_ALL() {
            let nid = searchParams.get('nid')
            if (nid !== null){
                let formData = new FormData();
                formData.append('id', Number(nid));
                // GET API =================================================
                await axios({
                    method: "post",
                    url: "/api/news/getNewsById",
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                })
                .then(function (response) {
                    // console.log(response.data.dataenum)
                    setGetCategoryId(response.data.dataenum.categoryId)
                })
                .catch(function (response) {
                    console.log(response);
                });
                // =========================================================
            }
        }
        SET_PAGE_ALL();
    }, [searchParams]);

    return (
        <div className='p-2'>
            <div className='head-topic pb-2 mb-2' style={{fontSize: "1.5rem"}}>
                <a href="#nolink" className='notLink' onClick={ e => e.preventDefault() }>ประเภทข่าว</a>
            </div>
            <ListGroup flush style={{fontSize: "0.9rem"}}>
                {listCategory.map(item => (
                    <ListGroupItem
                        href={`category/list?cname=${item.categoryName}&cid=${item.categoryId}&page=1`}  
                        tag="a"
                        key={Number(item.categoryId)}
                        style={{
                            minWidth: 'max-content',
                            ...((GetCategoryId===item.categoryId) ? {color: "#cc230b"} : {})
                        }}
                    >
                        {item.categoryName}
                    </ListGroupItem>
                ))}


            </ListGroup>
        </div>
    );
}
