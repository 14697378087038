import React from 'react';
import { Col, Input, Pagination, PaginationItem, PaginationLink, Table } from 'reactstrap';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination, useSortBy } from 'react-table';
import { matchSorter } from 'match-sorter'
import './CreateTable.css';

export default function CreateTable({ columns, data }) {


    const filterTypes = React.useMemo(
        () => ({
            fuzzyText: fuzzyTextFilterFn,
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        // footerGroups,
        // rows,
        prepareRow,
        page,
        setGlobalFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
        // currentPage,
        // onPageChange,
    } = useTable({
        columns,
        data,
        filterTypes,
        // initialState: { pageIndex: 2 },
        // initialState: { pageSize: 5 },
    },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        usePagination,
    )

    // Render the UI for your table
    return (

        <>

            <div className="pagination pagination-select-admin">

                <GlobalFilter
                    setGlobalFilter={setGlobalFilter}
                />
                <select
                    className='select-pagination'
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[5, 10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>

            <Table {...getTableProps()} bordered hover responsive>
                <thead className="text-center">
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (

                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    onClick={() => column.toggleSortBy(!column.isSortedDesc)}
                                >
                                    <div style={{ width: 'auto' }}>
                                        {column.render("Header")}
                                        {/* Add a sort direction indicator */}
                                        {/* {console.log(column.isSortedDesc)} */}
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? <img src="\sort\sort_desc.png" alt='' />
                                                : <img src="\sort\sort_asc.png" alt='' />
                                            : <img src="\sort\sort_both.png" alt='' />
                                        }
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </Table >

            {/* <div className="pagination" style={{ marginBottom: 50 }}>
                <div className='all-button-pagination-admin'>
                    <button className='button-pagination-admin' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {'หน้าแรก'}
                    </button>{' '}
                    <button className='button-pagination-admin' onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {'หน้าก่อนหน้า'}
                    </button>{' '}
                </div>

                <span>
                    <strong>
                        หน้า {pageIndex + 1} จาก {pageOptions.length}
                    </strong>{' '}
                </span>

                <div className='all-button-pagination-admin'>
                    <button className='button-pagination-admin' onClick={() => nextPage()} disabled={!canNextPage}>
                        {'หน้าต่อไป'}
                    </button>{' '}
                    <button className='button-pagination-admin' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {'หน้าสุดท้าย'}
                    </button>{' '}
                </div>
            </div> */}
            <Pagination
                aria-label="Page navigation example"
                size="md"
            >

                <PaginationItem style={{ display: 'flex' }}>
                    <PaginationLink
                        className='mx-1'
                        first
                        onClick={() => gotoPage(0)} disabled={!canPreviousPage}
                    >
                        หน้าแรก
                    </PaginationLink>
                    {/* </PaginationItem>
                <PaginationItem> */}
                    <PaginationLink
                        className='mx-1'
                        previous
                        onClick={() => previousPage()} disabled={!canPreviousPage}
                    >
                        หน้าก่อนหน้า
                    </PaginationLink>
                </PaginationItem>


                <span>
                    <strong>
                        หน้า {pageIndex + 1} จาก {pageOptions.length}
                    </strong>{' '}
                </span>


                <PaginationItem style={{ display: 'flex' }}>
                    <PaginationLink
                        className='mx-1'
                        onClick={() => nextPage()} disabled={!canNextPage}
                        next
                    >
                        หน้าต่อไป
                    </PaginationLink>
                    {/* </PaginationItem>
                <PaginationItem> */}
                    <PaginationLink
                        className='mx-1'
                        last
                        onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}
                    >
                        หน้าสุดท้าย
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
        </>
    )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

fuzzyTextFilterFn.autoRemove = val => !val

function GlobalFilter({
    globalFilter,
    setGlobalFilter,
}) {
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <Col className='row searchtabadmin' style={{}}>
            <Input
                className="input"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder="ค้นหา"
            />
            {/* <div
                className="searchtableadminend"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
            </div> */}
        </Col>
    )
}