import './../../custom_error404.css'
export function Animation404() {
    return (
        <div className='p-2'>
            <section className="page_404">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 ">
                            <div className="col-sm-10 col-sm-offset-1  text-center">
                                <div className="four_zero_four_bg">
                                    <h1 className="text-center ">404</h1>
                                </div>
                                <div className="contant_box_404">
                                    <h3 className="h2">
                                        ดูเหมือนว่าคุณกำลังหลงทาง
                                    </h3>
                                    <p>ไม่พบหน้ที่คุณต้องการค้นหา</p>
                                    <a href='/' className="link_404">กลับสู่หน้าหลัก</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
