import { Home } from "./page/Home";
import { News } from "./page/News";
import { PostNews } from "./page/PostNews";
import { ShowList } from "./page/ShowList";
import { AdminLoginPage } from "./page/AdminLogin";
import { AdminLogoutPage } from "./page/AdminLogout";
import { Error404 } from "./page/Error404";
// import { AdminManageUsersPage } from "./page/AdminManageUsers";
// import { AdminManageNewsPage } from "./page/AdminManageNews";
// import { AdminNewsEditorPage } from "./page/AdminNewsEditor";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/*',
        element: <Error404 />
    },
    {
        path: '/news',
        element: <News />
    },
    {
        path: '/post/news',
        element: <PostNews />
    },
    {
        // group/list?gname=News&gid=1
        path: '/group/list',
        element: <ShowList search="group" />
    },
    {
        // category/list?cname=คลิป&cid=15
        path: '/category/list',
        element: <ShowList search="category" />
    },
    {
        // tag/list/?tname=บรรยากาศรัก
        path: '/tag/list',
        element: <ShowList search="tag" />
    },
    {
        // search/list?keywords=test
        path: '/search/list',
        element: <ShowList search="search" />
    },
    {
        path: '/admin/login',
        element: <AdminLoginPage />
    },
    {
        path: '/admin/logout',
        element: <AdminLogoutPage />
    },
    {
        path: '/page/error404',
        element: <Error404 />
    },
    // {
    //     path: '/admin/manageUsers',
    //     element: <AdminManageUsersPage />
    // },
    // {
    //     path: '/admin/manageNews',
    //     element: <AdminManageNewsPage />
    // },
    // {
    //     path: '/admin/newsEditor',
    //     element: <AdminNewsEditorPage />
    // }
];

export default AppRoutes;
