import React from 'react';
import { AdminLogin } from "../containers/AdminLogin";



export function AdminLoginPage() {

    
    return (
        <>
            <AdminLogin />
        </>
    );
}