
import { NewsContent } from '../components/Content/NewsContent';
import { SubContent } from '../components/Content/SubContent';

export function Content() {
    return (
        <>
            <hr />
            <div className='container'>
                <div className='row'>
                    {/* <div className='col-9' style={{ borderColor: "red",borderStyle: "solid" }}> */}
                    <div className='col-lg-10 col-md-12 ' style={{  }}>
                        <NewsContent />
                    </div>
                    {/* <div className='col-3' style={{ borderColor: "green",borderStyle: "solid" }}> */}
                    <div className='col-lg-2 col-md-12' style={{  }}>
                        <SubContent />
                    </div>
                </div>
            </div>
        </>

    )
}