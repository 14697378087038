import React, { useState, useEffect } from 'react';
import { Container, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';
import CreateTable from "../Admin/CreateTableAdminComponent";
import { Link } from 'react-router-dom';

import { Animation404 } from '../Content/Animation404';

export function ManageUsers() {
    const MySwal = withReactContent(Swal)
    const [PageError, setPageError] = useState(false)


    const [modal, setModal] = useState(false);
    // const toggle = () => setModal(!modal);
    const [modalHeader, setModalHeader] = useState("เพิ่มข้อมูลสมาชิก")

    const [adminUsers, setAdminUsers] = React.useState([])

    React.useEffect(() => {
        getAdminUsers()

    }, [])

    async function getAdminUsers() {
        const res = await fetch("/api/admin/getUsers")
        const data = await res.json()
        //console.log(data)
        setAdminUsers(data.dataenum)

    }

    const adminUsersTbRow = React.useMemo(
        () => adminUsers, [adminUsers]
    )
    const adminUsersTbColumns = [

        {
            Header: '#',
            // accessor: 'no',
            Cell: (row) => {
                return <div>{Number(row.row.id) + 1}</div>;
            },
        },
        {
            Header: 'Username',
            accessor: 'username',
        },
        {
            Header: 'Full Name',
            // accessor: 'firstname',
            Cell: cellInfo => (
                <>
                    {cellInfo.row.original.firstname + " " + cellInfo.row.original.lastname}
                </>
            )
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'Role',
            accessor: 'role',
            Cell: ({ value }) => (
                <div>
                    {value === 1 ? "ผู้ดูแลระบบ" : "นักข่าว"}
                </div>
            )
        },
        {
            Header: 'สถานะ',
            accessor: 'status',
            Cell: ({ value }) => (
                <div>
                    {value === 1 ? "เปิดใช้งาน" : "ไม่ใช้งาน"}
                </div>
            )
        },
        {
            Header: 'จัดการ',
            // accessor: 'operbudListCode',
            Cell: cellInfo => (
                <div className="text-center">
                    <Button className="mx-1" color='warning' onClick={() => formOnEdit(cellInfo.row.original)}>แก้ไข</Button>
                </div>
            )
        }
    ]

    const formInitialValues = {
        userId: 0,
        username: "",
        password: "",
        firstname: "",
        lastname: "",
        email: "",
        lindId: "",
        role: 2,
        status: 1
    }

    const formSchema = yup.object().shape({
        userId: yup.number(),
        username: yup.string().required(),
        password: yup.string().when("userId", {
            is: 0,
            then: yup.string().required()
        }),
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        email: yup.string().email(),
        lindId: yup.string(),
        role: yup.number(),
        status: yup.number()
    })

    const formFileOnSubmit = async (values, actions) => {
        // console.log(values);

        try {
            let res = await fetch("/api/admin/saveUsers", {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(values)
            });
            let resJson = await res.json();
            // console.log(resJson);
            if (resJson.status === 1) {
                MySwal.fire({
                    title: <strong>{resJson.message}</strong>,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                })
            } else {
                MySwal.fire({
                    title: <strong>{resJson.message}</strong>,
                    icon: 'error',
                    showConfirmButton: true,
                })
            }
            getAdminUsers()
        } catch (err) {
            //console.log(err);
            MySwal.fire({
                title: <strong>เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง</strong>,
                icon: 'error',
                showConfirmButton: true,
            })
        }

        actions.resetForm();
        toggle(false)

    }

    const formFormik = useFormik({
        initialValues: formInitialValues,
        validationSchema: formSchema,
        onSubmit: formFileOnSubmit,
    })

    function formOnAdd() {
        setModalHeader("เพิ่มข้อมูลสมาชิก")
        toggle(false)

    }

    function formOnEdit(data) {
        // console.log(data)
        setModalHeader("แก้ไขข้อมูลสมาชิก")
        formFormik.setValues(data)
        toggle(false)
    }

    const toggle = (close) => {
        setModal(!modal)
        if (close) {
            formFormik.resetForm()
        }
    }

    // Check role
    useEffect(() => {
        const items = JSON.parse(localStorage.getItem('currentUser'))
        if (items !== null && Number(items['role']) === 1) {
            setPageError(false)
        } else {
            setPageError(true)
        }
    }, [])

    return (
        <>
            {PageError === true ? (
                <Animation404 />
            ) : (

                <>
                    <Container>
                        <h1 className='py-2'>จัดการผู้ใช้งาน</h1>
                        <hr />
                        <Button color={"success"} onClick={() => formOnAdd()}>
                            เพิ่มผู้ใช้งาน
                        </Button>

                        <Link to='/'>
                            <Button style={{ float: "right" }} color={"outline-danger"}>
                                ย้อนกลับ
                            </Button>
                        </Link>

                        <br />
                        <br />

                        <CreateTable columns={adminUsersTbColumns} data={adminUsersTbRow} />


                    </Container>


                    <Modal isOpen={modal} toggle={toggle} backdrop={true} fade={true} size={"lg"}>
                        <ModalHeader toggle={() => toggle(true)}>{modalHeader}</ModalHeader>
                        <ModalBody>

                            <Form>

                                <FormGroup className='d-none'>
                                    <Label for="userId">
                                        userId
                                    </Label>
                                    <Input
                                        id="userId"
                                        name="userId"
                                        // placeholder="with a placeholder"
                                        type="text"
                                        disabled={true}
                                        invalid={formFormik.errors.userId ? true : false}
                                        {...formFormik.getFieldProps('userId')}
                                    />
                                </FormGroup>

                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="username">
                                                Username
                                            </Label>
                                            <Input
                                                id="username"
                                                name="username"
                                                // placeholder="with a placeholder"
                                                type="text"
                                                invalid={formFormik.errors.username ? true : false}
                                                {...formFormik.getFieldProps('username')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="password">
                                                Password
                                                {modalHeader === "แก้ไขข้อมูลสมาชิก" ? (
                                                    <> *หากต้องการเปลี่ยนรหัสผ่านกรุณากรอก</>
                                                ) : null}
                                            </Label>
                                            <Input
                                                id="password"
                                                name="password"
                                                // placeholder="password placeholder"
                                                type="password"
                                                invalid={formFormik.errors.password ? true : false}
                                                {...formFormik.getFieldProps('password')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="firstname">
                                                ชื่อ
                                            </Label>
                                            <Input
                                                id="firstname"
                                                name="firstname"
                                                // placeholder="with a placeholder"
                                                type="text"
                                                invalid={formFormik.errors.firstname ? true : false}
                                                {...formFormik.getFieldProps('firstname')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="lastname">
                                                นามสกุล
                                            </Label>
                                            <Input
                                                id="lastname"
                                                name="lastname"
                                                // placeholder="password placeholder"
                                                type="text"
                                                invalid={formFormik.errors.lastname ? true : false}
                                                {...formFormik.getFieldProps('lastname')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="email">
                                                Email
                                            </Label>
                                            <Input
                                                id="email"
                                                name="email"
                                                // placeholder="with a placeholder"
                                                type="email"
                                                invalid={formFormik.errors.email ? true : false}
                                                {...formFormik.getFieldProps('email')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="lindId">
                                                Line Id
                                            </Label>
                                            <Input
                                                id="lindId"
                                                name="password"
                                                // placeholder="password placeholder"
                                                type="text"
                                                invalid={formFormik.errors.lindId ? true : false}
                                                {...formFormik.getFieldProps('lindId')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <FormGroup>
                                    <Label for="role">
                                        Role
                                    </Label>
                                    <Input
                                        id="role"
                                        name="role"
                                        type="select"
                                        invalid={formFormik.errors.role ? true : false}
                                        {...formFormik.getFieldProps('role')}
                                    >
                                        <option value={1}>
                                            ผู้ดูแลระบบ
                                        </option>
                                        <option value={2}>
                                            นักข่าว
                                        </option>
                                    </Input>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="status">
                                        Status
                                    </Label>
                                    <Input
                                        id="status"
                                        name="status"
                                        type="select"
                                        invalid={formFormik.errors.status ? true : false}
                                        {...formFormik.getFieldProps('status')}
                                    >
                                        <option value={1}>
                                            เปิดใช้งาน
                                        </option>
                                        <option value={2}>
                                            ไม่ใช้งาน
                                        </option>
                                    </Input>
                                </FormGroup>

                            </Form>

                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" onClick={formFormik.handleSubmit}>
                                บันทึก
                            </Button>{' '}
                            <Button color="secondary" onClick={() => toggle(true)}>
                                ยกเลิก
                            </Button>
                        </ModalFooter>
                    </Modal>

                </>
            )}


        </>
    )
}