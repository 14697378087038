import moment from 'moment-timezone';

export function Copyright() {
    // const dateTime_real = moment().tz("Asia/Bangkok").format('YYYY-MM-DD HH:mm:ss')
    const year = moment().tz("Asia/Bangkok").format('YYYY')
    return (
        <div className="text-center py-3 footer-copyright">
            &copy; Siambanterng.com, {year}. All rights reserved.
        </div>
    )
}