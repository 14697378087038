import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import './custom.css';
import './CKEditor.css';
import { AdminManageUsersPage } from "./page/AdminManageUsers";
import { AdminManageNewsPage } from "./page/AdminManageNews";
import { AdminNewsEditorPage } from "./page/AdminNewsEditor";
import AuthProtectedComponent from "./components/Admin/AuthProtectedComponent";

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Routes>
        {AppRoutes.map((route, index) => {
          const { element, ...rest } = route;
          return <Route key={index} {...rest} element={element} />;
        })}

        <Route element={<AuthProtectedComponent />}>

          <Route
            path="/admin/manageUsers"
            element={<AdminManageUsersPage />}
          />

          <Route
            path="/admin/manageNews"
            element={<AdminManageNewsPage />}
          />

          <Route
            path="/admin/newsEditor"
            element={<AdminNewsEditorPage />}
          />

        </Route>



      </Routes>
    );
  }
}
