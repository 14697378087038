import { Navigate, Outlet } from "react-router-dom";
import React from "react";

const AuthProtectedComponent = () => {

    const items = JSON.parse(localStorage.getItem('currentUser'))



    React.useEffect(() => {
        async function isAuth() {
            if (items !== null) {
                let postData = new FormData()
                postData.append("token", items.token)
                let res = await fetch("/api/admin/isAuth", {
                    method: 'POST',
                    body: postData
                });
                const user = await res.json();
                // console.log(user);
                localStorage.setItem("currentUser", JSON.stringify({
                    ...items,
                    isAuth: user.isAuth,
                    message: user.message
                }))
            }
        }
        isAuth()
    }, [items])

    // console.log(items)
    return items?.isAuth ? <Outlet /> : <Navigate to='/admin/login' />

}

export default AuthProtectedComponent