import React, { useEffect, useState } from 'react';
import logo from '../../images/logo-web.png'
import * as Icon from 'react-bootstrap-icons';
import axios from 'axios';
import { useSearchParams } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Swal from 'sweetalert2'

export function Header() {
    const [navItem, setNavItem] = useState([])
    const [role, setrole] = useState(false)
    const [login, setlogin] = useState(false)
    const [roleName, setrolename] = useState(false)
    const [fullName, setfullName] = useState(false)
    const [pathAdmin, setpathAdmin] = useState(false)

    React.useEffect(() => {
        getData()
    }, [])

    async function getData() {
        const res = await fetch("/api/news/getGroupAndCates")
        const data = await res.json()
        setNavItem(data.dataenum)
    }

    const [searchParams] = useSearchParams();
    const [GetGroupID, setGetGroupID] = useState(false)
    useEffect(() => {
        async function SET_PAGE_ALL() {
            let nid = searchParams.get('nid')
            let cid = searchParams.get('cid')
            let gid = searchParams.get('gid')
            if (nid !== null) {
                let formData = new FormData();
                formData.append('id', Number(nid));
                // GET API =================================================
                await axios({
                    method: "post",
                    url: "/api/news/getNewsById",
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                })
                    .then(function (response) {
                        // console.log(response.data.dataenum)
                        setGetGroupID(response.data.dataenum.groupId)
                    })
                    .catch(function (response) {
                        console.log(response);
                    });
                // =========================================================
            } else if (gid !== null) {
                // console.log(gid)
                setGetGroupID(Number(gid))
            } else if (cid !== null) {
                await axios({
                    method: "get",
                    url: "/api/news/getMCategory",
                }).then(function (response) {
                    // console.log(response.data.dataenum)
                    let dataCategory = response.data.dataenum

                    for (let index = 0; index < dataCategory.length; index++) {
                        if (dataCategory[index]['categoryId'] === Number(cid)) {
                            setGetGroupID(Number(dataCategory[index]['groupId']))
                        }
                    }
                })
                    .catch(function (response) {
                        console.log(response);
                    });
            }
        }
        SET_PAGE_ALL();
    }, [searchParams]);

    function onsubmitSearch(textsearch) {
        let search = textsearch
        if (search !== "") {
            let linkGo = `search/list?keywords=${search}&page=1`
            window.location.href = linkGo
        }
    }

    function handleSubmit(event) {
        event.preventDefault()
        let keyword = event.currentTarget.elements.searchID.value
        // console.log(keyword)
        if (keyword !== "" && keyword !== null) {
            onsubmitSearch(keyword)
        } else {
            // console.log("99")
            Swal.fire({
                title: 'พบความผิดพลาด!',
                text: 'โปรดกรอกคำที่ต้องการค้นหา',
                icon: 'error',
                confirmButtonText: 'ตกลง'
            })
        }
    }

    // Get roles
    useEffect(() => {
        const items = JSON.parse(localStorage.getItem('currentUser'))
        // console.log(items)
        if (items !== null) {
            setrole(items['role'])
            setlogin(true)
            setfullName(`${items['firstname']} ${items['lastname']}`)
            if (Number(items['role']) === 1){
                setrolename("ผู้ดูแลระบบ")
            } else if (Number(items['role']) === 2){
                setrolename("นักข่าว")
            } else {
                setrolename("")
            }
            if (((window.location.pathname).indexOf('admin/manageNews') > -1) || (window.location.pathname).indexOf('admin/newsEditor') > -1) {
                setpathAdmin('admin/manageNews')
                // console.log('admin/manageNews')
            } else if ((window.location.pathname).indexOf('admin/manageUsers') > -1) {
                setpathAdmin('admin/manageUsers')
                // console.log('admin/manageUsers')

            }
        }
    }, [])


    return (
        <div>
            <Navbar bg="light" expand="md" className='bgw'>
                <Container fluid className='bgw'>
                    <Navbar.Brand href="/">
                        <img alt="logo" src={logo} width="70px" className='d-inline-block align-top' />
                    </Navbar.Brand>
                    <Navbar.Brand href="/" className='d-md-none'>
                        Siambanterng
                    </Navbar.Brand>



                    <Navbar.Toggle aria-controls="navbarScroll" />



                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            style={{
                                maxHeight: '400px',
                                // width: 'max-content' 
                            }}
                            navbarScroll
                        >
                            <Nav.Link href="/" key="home" id='menu-home' style={((GetGroupID === false) && (pathAdmin === false)) ? { color: "#cc230b" } : {}}>
                                <div style={{
                                    display: "inline-flex",
                                    minWidth: 'max-content'
                                }}>
                                    <Icon.HouseFill size={20} />&nbsp;หน้าหลัก
                                </div>
                            </Nav.Link>

                            {/* {Number(role) === } */}
                            {login === true ? (
                                <>

                                    {((Number(role) === 2) || (Number(role) === 1)) ? (
                                        <Nav.Link href="/admin/manageNews" key="manageNews" id='menu-manageNews' style={( (pathAdmin === "admin/manageNews")) ? { color: "#cc230b" } : {}}>
                                            <div style={{
                                                display: "inline-flex",
                                                minWidth: 'max-content'
                                            }}>
                                                <Icon.Newspaper size={20} />&nbsp;จัดการข่าว
                                            </div>
                                        </Nav.Link>
                                    ) : (
                                        null
                                    )}
                                    {Number(role) === 1 ? (
                                        <Nav.Link href="/admin/manageUsers" key="manageUsers" id='menu-manageUsers' style={((pathAdmin === "admin/manageUsers")) ? { color: "#cc230b" } : {}}>
                                            <div style={{
                                                display: "inline-flex",
                                                minWidth: 'max-content'
                                            }}>
                                                <Icon.PeopleFill size={20} />&nbsp;จัดการผู้ใช้งาน
                                            </div>
                                        </Nav.Link>
                                    ) : (
                                        null
                                    )}
                                    
                                </>
                            ) : (
                                <>
                                    {navItem.map((group, index) => (
                                        <NavDropdown title={
                                            group.groupName
                                        }
                                            key={Number(group.groupId)}
                                            id={'menu-' + group.groupId}
                                            className={(GetGroupID === group.groupId) ? "classSetRedMenu" : false}
                                        >
                                            {group.categories.map((cate, i) => (
                                                <NavDropdown.Item key={Number(cate.categoryId)} href={`category/list?cname=${cate.categoryName}&cid=${cate.categoryId}&page=1`}>{cate.categoryName}</NavDropdown.Item>
                                            ))}
                                        </NavDropdown>
                                    ))}
                                </>
                            )}



                        </Nav>
                        <Form className="d-flex my-2" onSubmit={handleSubmit}>
                            <Form.Control
                                type="search"
                                placeholder="ค้นหา"
                                className="me-1"
                                aria-label="Search"
                                style={{ minWidth: "10rem" }}
                                id="searchID"
                            />
                            <Button variant="outline-danger" type="submit" >
                                ค้นหา
                            </Button>
                        </Form>
                        <div className="vr d-none d-md-block"
                            style={{
                                marginLeft: "0.5rem",
                                marginRight: "0.5rem"
                            }}
                        ></div>
                        {login === true ? (
                            <div>
                                <a href="/admin/logout" >
                                    <button
                                        type="button"
                                        className="btn btn-outline-dark my-2"
                                        style={{width: "100%"}}
                                    >
                                        Logout
                                    </button>
                                </a>
                                <p style={{margin: "0 0 0 0"}}>{fullName} ({roleName})</p>
                            </div>
                        ) : (
                            <a href="/admin/login" >
                                <button
                                    type="button"
                                    className="btn btn-outline-dark my-2"
                                >
                                    Login
                                </button>
                            </a>
                        )}

                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}