
import React, { useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { SaveLog } from "../../Utils/SaveLog";
// import $ from "jquery"
import axios from "axios";
// import moment from 'moment-timezone';
import { Helmet } from "react-helmet";
import * as Share from 'react-share';
import { Animation404 } from '../Animation404';

export function DetailNews() {

    const [searchParams] = useSearchParams();
    const [content, setContent] = useState({})
    const [PageError, setPageError] = useState(false)
    const [newsViews, setNewsViews] = useState(0)


    const [groupName, setgroupName] = useState(false)
    const [groupID, setgroupID] = useState(false)

    const [categoryName, setcategoryName] = useState(false)
    // const [categoryID, setcategoryID] = useState(false)


    if (searchParams.get('nid') === null || searchParams.get('nid') === "") {
        //param error
        window.location.replace("/page/error404")
    }

    // Set Meta
    const [metaUrl, setmetaUrl] = useState(false)
    const [metaTitle, setmetaTitle] = useState(false)
    const [metaDescription, setmetaDescription] = useState(false)
    const [metaImage, setmetaImage] = useState(false)
    const [localtionUrl, setlocaltionUrl] = useState(false)

    React.useEffect(() => {
        setlocaltionUrl(window.location.href)
        // console.log(localtionUrl)
    }, [localtionUrl])

    React.useEffect(() => {
        setmetaUrl(`https://siambanterng.com${window.location.pathname + window.location.search}`)
        function getNews() {
            let formData = new FormData();
            formData.append('id', Number(searchParams.get('nid')));
            axios({
                method: "post",
                url: "/api/news/getNewsById",
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(async function (response) {
                    if (Number(response.data.status) === 0) {
                        setPageError(true)
                    }
                    else {
                        setPageError(false)

                        //handle success
                        // console.log(response.data.dataenum)
                        await setContent(response.data.dataenum)

                        // var picture = document.getElementsByClassName("image_div_name");
                        // var src = picture[0].children[0].src;
                        await setgroupName(response.data.dataenum.group)
                        await setgroupID(response.data.dataenum.groupId)
                        await setcategoryName(response.data.dataenum.category)
                        // setcategoryID(response.data.dataenum.categoryId)

                        // // meta
                        // await setmetaTitle(content.title)

                        // const pGet_content = await String(content.content).replaceAll('&nbsp;', " ")
                        // const parser_content = await new DOMParser();
                        // const doc_content = await parser_content.parseFromString(pGet_content, 'text/html');
                        // try {
                        //     const value_content = await doc_content.querySelector('p').textContent;
                        //     const len_ = value_content.length
                        //     let n_ = 0
                        //     try {


                        //         while (true) {
                        //             if (value_content[n_] === " ") {
                        //                 n_ += 1
                        //             } else {
                        //                 await setmetaDescription(value_content.slice(n_, len_))
                        //                 break
                        //             }
                        //         }
                        //     } catch (error) {
                        //         setmetaDescription(value_content)
                        //     }

                        // } catch (error) { }


                        // const htmlString_scr = content.thumbnail;
                        // const parser_scr = new DOMParser();
                        // const htmlDoc_scr = parser_scr.parseFromString(htmlString_scr, 'text/html');
                        // const imgTags_scr = htmlDoc_scr.getElementsByTagName("img");
                        // for (var i = 0; i < imgTags_scr.length; i++) {
                        //     var imgTag = imgTags_scr[i];
                        //     if (imgTag.parentNode.classList.contains("image")) {
                        //         var src = `https://siambanterng.com${imgTag.getAttribute("src")}`;
                        //         // console.log(src)
                        //         setmetaImage(src)
                        //     }
                        // }

                    }
                })
                .catch(function (response) {
                    //handle error
                    console.log(response);
                    setPageError(true)
                });
        }

        function getNewsViews() {
            let formData = new FormData();
            formData.append('id', Number(searchParams.get('nid')));
            axios({
                method: "post",
                url: "/api/news/getNewsViews",
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(function (response) {
                    //handle success
                    setNewsViews(response.data.dataenum)
                })
                .catch(function (response) {
                    //handle error
                    console.log(response);
                });
        }

        getNews()
        SaveLog(0, Number(searchParams.get('nid')), 'news')
        getNewsViews()
    }, [content.content, content.thumbnail, content.title, searchParams])






    function numberWithCommas(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return (
        <div className='pt-2 pb-5 container-new'>

            {/* <Helmet>
                <title>{metaTitle}</title>
                <meta data-react-helmet="true" name="description"
                    content={metaDescription} />
                <link data-react-helmet="true" rel="image_src" href={metaImage} />
                <meta data-react-helmet="true" property="dable:image" content={metaImage} />

                <meta data-react-helmet="true" property="og:url" content={metaUrl} />
                <meta data-react-helmet="true" property="og:type" content="website" />
                <meta data-react-helmet="true" property='og:title' content={metaTitle} />
                <meta data-react-helmet="true" property='og:description'
                    content={metaDescription} />
                <meta data-react-helmet="true" property="og:image" content={metaImage} />


                <meta data-react-helmet="true" name="twitter:card" content="summary" />
                <meta data-react-helmet="true" name='twitter:title' content={metaTitle} />
                <meta data-react-helmet="true" name='twitter:description'
                    content={metaDescription} />
                <meta data-react-helmet="true" name="twitter:image" content={metaImage} />

            </Helmet> */}

            {
                PageError === true ?
                    <Animation404 />
                    :
                    (<>
                        <div>
                            <nav className='classBread' aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item"><a href={`/group/list?gname=${groupName}&gid=${groupID}&page=1`}>{groupName}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{categoryName}</li>
                                </ol>
                            </nav>
                        </div>


                        <h2 className='content_title'>{content.title}</h2>


                        <hr style={{ marginBottom: "1.5rem" }} />


                        <div>
                            <div className='setResizeContentdetailProFile' dangerouslySetInnerHTML={{ __html: content.thumbnail }}  ></div>
                            <div className='dateTimePrfile'>
                                {/* <p className='text-secondary text-start'> { `${moment(new Date(content.createDateSt)).format("DD-MM-YYYY HH:mm")} น. }` } </p> */}
                                <p className='text-secondary text-start'>โพสต์: {content.createDateSt} </p>
                                <div className='text-secondary text-end'>
                                    {`Views : ${numberWithCommas(newsViews)}`}
                                    <div style={{ marginTop: '1rem' }}>
                                        <Share.FacebookShareButton
                                            url={localtionUrl}
                                            title={"แชร์ Facebook"}
                                            className="sharebutton"
                                        >
                                            <Share.FacebookIcon size={35} round />
                                        </Share.FacebookShareButton>

                                        <Share.TwitterShareButton
                                            url={localtionUrl}
                                            title={"แชร์ Twitter"}
                                            className="sharebutton"
                                        >
                                            <Share.TwitterIcon size={35} round />
                                        </Share.TwitterShareButton>

                                        <Share.EmailShareButton
                                            url={localtionUrl}
                                            subject={'แชร์ Email'}
                                            body="body"
                                            className="sharebutton"
                                        >
                                            <Share.EmailIcon size={35} round />
                                        </Share.EmailShareButton>

                                        <Share.LineShareButton
                                            url={localtionUrl}
                                            title={'แชร์ Line'}
                                            className="sharebutton"
                                        >
                                            <Share.LineIcon size={35} round />
                                        </Share.LineShareButton>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <hr style={{ marginBottom: "1.5rem" }} />


                        <div>
                            <div
                                className="ck ck-content"
                                lang="th"
                                dir="ltr"
                                role="textbox"
                                dangerouslySetInnerHTML={{ __html: content.content }}
                            >
                            </div>
                        </div>

                        {/* <div dangerouslySetInnerHTML={{ __html: content.content }} /> */}

                        <br />
                        <br />
                        <hr />
                        <div className="tags-input-container" >
                            {content?.tags?.map((item, index) => (
                                <a href={`tag/list?tname=${item.tagName}&page=1`} target="_blank" className="tags-link" rel="noreferrer" key={Number(index)}>
                                    <div className="tag-item">
                                        <span className="text">{item.tagName}</span>
                                    </div>
                                </a>
                            ))}
                        </div>

                        {/* <img src='/images/2022-12-13/gPOBpgNqdQdroJ1OlnfGxI__.png' alt="test" />; */}


                    </>)
            }


        </div>
    )
}