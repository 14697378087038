
import React, { useState, useEffect } from 'react';
import { useSearchParams, useLocation, Link } from "react-router-dom";
import axios from "axios";
import Button from 'react-bootstrap/Button';

export function LoadContentList(props) {

    const [searchParams] = useSearchParams()
    const location = useLocation()

    let searchUrl = location.pathname + location.search.slice(0, (searchParams.get('page').length * (-1)))

    const [listItem, setlistItem] = useState([{}])

    const [headerName, setHeaderName] = useState("")

    const [lenItem, setlenItem] = useState(0)

    const [nextPage, setNextPage] = useState({ active: false, url: "" })
    const [previousPage, setPreviousPage] = useState({ active: false, url: "" })


    const rangePageMax = 5
    const [rangePageCurrent, setrangePageCurrent] = useState([])
    const [PageCurrent, setPageCurrent] = useState(false)



    useEffect(() => {
        async function getItem() {
            // Set data post
            var dataSearch = {}
            var getPropSearch = await props.prop.prop.search
            async function setDataPost(searchByProp) {
                //type 1 = Search By Name And Tag
                //type 2 = Search By Category
                //type 3 = Search By Group
                //type 4 = Search By Tag
                if (searchByProp === "search") {
                    dataSearch['Type'] = 1
                    dataSearch['Search'] = searchParams.get('keywords')
                    setHeaderName(`ผลการค้นหา: "${searchParams.get('keywords')}"`)
                } else if (searchByProp === "category") {
                    dataSearch['Type'] = 2
                    dataSearch['Search'] = searchParams.get('cid')
                    setHeaderName(`ประเภท: ${searchParams.get('cname')}`)
                } else if (searchByProp === "group") {
                    dataSearch['Type'] = 3
                    dataSearch['Search'] = searchParams.get('gid')
                    setHeaderName(`กลุ่ม: ${searchParams.get('gname')}`)
                } else if (searchByProp === "tag") {
                    dataSearch['Type'] = 4
                    dataSearch['Search'] = searchParams.get('tname')
                    setHeaderName(`Tag: ${searchParams.get('tname')}`)
                }
                dataSearch['Page'] = searchParams.get('page')
            }
            await setDataPost(getPropSearch);

            async function postGetItem(dataFrom) {
                await axios({
                    method: "post",
                    url: "/api/news/searchNews",
                    data: JSON.stringify(dataFrom),
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then(async function (response) {
                        // console.log(response.data.dataenum)
                        let c = 1
                        let temp_ = {}
                        let currentG = null
                        for (let index = 0; index < Number(response.data.dataenum.pages); index++) {
                            if ((`G_${c}` in temp_) === false) {
                                temp_[`G_${c}`] = []
                            }
                            temp_[`G_${c}`].push(index + 1)
                            if (Number(response.data.dataenum.currentPage) === index + 1) {
                                currentG = `G_${c}`
                            }
                            if (temp_[`G_${c}`].length === rangePageMax) {
                                c += 1
                            }
                        }
                        setrangePageCurrent(temp_[currentG])
                        setPageCurrent(Number(response.data.dataenum.currentPage))
                        // currentPage

                        //handle success
                        setlistItem(response.data.dataenum.news)
                        // setlenItem((response.data.dataenum.news).length)
                        setlenItem(response.data.dataenum.totalNews)

                        if (response.data.dataenum.currentPage < response.data.dataenum.pages) {
                            setNextPage({ active: true, url: searchUrl + (response.data.dataenum.currentPage + 1) })
                        } else if (response.data.dataenum.currentPage === response.data.dataenum.pages) {
                            setNextPage({ active: false, url: searchUrl + (response.data.dataenum.currentPage + 1) })
                        }

                        if (response.data.dataenum.currentPage === 1) {
                            setPreviousPage({ active: false, url: searchUrl + (response.data.dataenum.currentPage - 1) })
                        } else {
                            setPreviousPage({ active: true, url: searchUrl + (response.data.dataenum.currentPage - 1) })
                        }



                    })
                    .catch(function (response) {
                        //handle error
                        console.log(response);
                    });
            }
            await postGetItem(dataSearch);
        }
        getItem()
    }, [props.prop.prop.search, props.search, searchParams, searchUrl])



    return (
        <div className='p-2'>
            <div >
                <div className='head-topic pb-2 mb-2'>
                    <a href="#nolink" className='notLink' onClick={e => e.preventDefault()}>
                        {headerName}
                        <span
                            style={{ fontSize: '1rem', marginLeft: '0.7rem' }}
                        >({lenItem} รายการ)</span>
                    </a>
                </div>
                <div>
                    {((listItem || []).length === 0) ? (
                        <div className='NoContent'>
                            ไม่มีข้อมูล
                        </div>
                    ) : (
                        <>
                            <div className="row row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-4 row-cols-xl-5">
                                {listItem.map((item, idx) => (
                                    <div className="col mb-4  mb-3 item-show" key={Number(idx)}>
                                        <a href={`/news?nid=${item.newsId}`} target="_parent" rel="noreferrer" >
                                            <div className="card" style={{ height: "100%" }}>
                                                <div className='card-img' dangerouslySetInnerHTML={{ __html: item.thumbnail }}></div> {/* thumbnail */}
                                                <div className="card-body">
                                                    <div className="card-text card-text-set">
                                                        {/* info */}
                                                        <div className='card-text-info' >
                                                            {item.category}
                                                        </div>
                                                        <div className='card-text-date'>
                                                            {/* {moment(new Date(item_detail.createDate)).format("DD-MM-YYYY HH:mm")} น. */}
                                                            {item.createDateSt}
                                                        </div>
                                                    </div>
                                                    <div className="card-title" >
                                                        <h2>
                                                            <div className='a_title'>
                                                                {item.title}
                                                            </div>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                            <hr />
                            <div class="container">
                                <div className='mb-3 mt-3 ' style={{ 
                                    display: "flex", 
                                    justifyContent: 'center', 
                                    lineHeight:"4rem",
                                    flexWrap:"wrap"
                                }}>
                                    {previousPage.active &&
                                        <Link to={previousPage.url} className="px-1 ">
                                            <Button
                                                variant="outline-danger"
                                            >
                                                ก่อนหน้า
                                            </Button>
                                        </Link>
                                    }
                                    {() => {

                                    }}
                                    {rangePageCurrent.map(item => (
                                        <Link to={`${searchUrl}${Number(item)}`} className="px-1" key={item}>
                                            {PageCurrent === item ? (
                                                <Button variant="dark" >
                                                    &nbsp; {item} &nbsp;
                                                </Button>
                                            ) : (
                                                <Button variant="outline-secondary" >
                                                    &nbsp; {item} &nbsp;
                                                </Button>
                                            )}

                                        </Link>
                                    ))}
                                    {nextPage.active &&
                                        <Link to={nextPage.url} className="px-1">
                                            <Button
                                                variant="outline-danger"
                                            >
                                                &nbsp; ถัดไป &nbsp;
                                            </Button>
                                        </Link>
                                    }
                                </div>
                            </div>
                        </>
                    )}

                </div>
            </div>
        </div>



        // <div className='p-2'>
        //     {listItem.map(item => (
        //         <div key={Number(item.GroupID)}>
        //             <div className='head-topic pb-2 mb-2'>
        //                 <a href={`group/list?gname=${item.GroupName}&gid=${item.GroupID}`} target="_blank" rel="noreferrer" >{item.GroupName}<span style={{marginLeft:'0.7rem',fontSize: '1rem'}}>(ดูทั้งหมด)</span></a>
        //             </div>
        //             <div>
        //                 {
        //                     (item.News || []).length === 0
        //                         ? <div className='NoContent'>
        //                             ไม่มีข้อมูล
        //                         </div>
        //                         : <div className="row row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-4 row-cols-xl-5">
        //                             {item.News.map(itemNew => (
        //                                 <div className="col mb-4  mb-3 item-show" key={Number(itemNew.newsId)}>
        //                                     <a href={`/news?nid=${itemNew.newsId}`} target="_parent" rel="noreferrer" >
        //                                         <div className="card" style={{ height: "100%" }}>
        //                                             <div className='card-img' dangerouslySetInnerHTML={{ __html: itemNew.thumbnail }}></div> {/* thumbnail */}
        //                                             <div className="card-body">
        //                                                 <div className="card-text card-text-set">
        //                                                     {/* info */}
        //                                                     <div className='card-text-info' >
        //                                                         {itemNew.category}
        //                                                     </div>
        //                                                     <div className='card-text-date'>
        //                                                         {/* {moment(new Date(item_detail.createDate)).format("DD-MM-YYYY HH:mm")} น. */}
        //                                                         {itemNew.createDateSt}
        //                                                     </div>
        //                                                 </div>
        //                                                 <div className="card-title" >
        //                                                     <h2>
        //                                                         <div className='a_title'>
        //                                                             {itemNew.title}
        //                                                         </div>
        //                                                     </h2>
        //                                                 </div>
        //                                             </div>
        //                                         </div>
        //                                     </a>
        //                                 </div>
        //                             ))}
        //                         </div>
        //                 }
        //             </div>
        //         </div>
        //     ))}

        // </div>
    )
}