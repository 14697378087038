export function SaveLog(UserId, NewsId, Activity) {

    // let res = fetch("/api/news/saveLog", {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({
    //         UserId: UserId,
    //         NewsId: NewsId,
    //         Activity: Activity
    //     })
    // });

    fetch("/api/news/saveLog", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            UserId: UserId,
            NewsId: NewsId,
            Activity: Activity
        })
    });

    return true
}