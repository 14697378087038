import React from 'react';
import { NavBar } from "../containers/NavBar";
import { Footer } from "../containers/Footer";
import ScrollButton from "../components/Utils/ScrollButton";
import { AdminNewsEditor } from "../containers/AdminNewsEditor";



export function AdminNewsEditorPage() {
    return (
        <>
            <NavBar />
            <AdminNewsEditor />
            <Footer />
            <ScrollButton />
        </>
    );
}
