import React from 'react';
import moment from 'moment-timezone';
import { Button } from 'reactstrap';
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';

export function Login() {

    const year = moment().tz("Asia/Bangkok").format('YYYY')
    const MySwal = withReactContent(Swal)
    // const [username, setUsername] = React.useState("admin");
    // const [password, setPassword] = React.useState("123456");
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");

    React.useEffect(() => {
        isAuth()

    }, [])

    async function isAuth() {
        const items = JSON.parse(localStorage.getItem('currentUser'))

        if (items !== null) {
            let postData = new FormData()
            postData.append("token", items.token)

            let res = await fetch("/api/admin/isAuth", {
                method: 'POST',
                body: postData
            });
            const user = await res.json();
            console.log(user);

            if (user.isAuth) {
                window.location.replace('/admin/manageNews')
            }
        }

    }

    let handleSubmit = async (e) => {
        e.preventDefault();
        localStorage.removeItem('currentUser');
        try {
            let res = await fetch("/api/admin/login", {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    username: username,
                    password: password,
                })
            });
            let resJson = await res.json();
            // console.log(resJson);

            if (resJson.isAuth) {
                localStorage.setItem('currentUser', JSON.stringify(resJson))
                window.location.replace('/admin/manageNews')
            } else {

                MySwal.fire({
                    title: <strong>{resJson.message}</strong>,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2500
                })

            }

        } catch (err) {
            console.log(err);

        }
    };

    return (
        <div>
            <div className="container mt-5 centered">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <h1 className="text-center mb-3"><b>เข้าสู่ระบบ</b></h1>
                        <form action='/api/login' method='post' onSubmit={handleSubmit}>
                            <div className="form-group mb-2">
                                <label htmlFor="emailUsername">Username</label>
                                <input type="text" value={username} onChange={e => setUsername(e.target.value)} name='emailUsername' className="form-control" id="emailUsername" aria-describedby="emailHelp" />
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="password">Password</label>
                                <input type="password" value={password} onChange={e => setPassword(e.target.value)} name='password' className="form-control" id="password" autoComplete="on" />
                            </div>
                            <Button color={"danger"} type="submit" block={true}>
                                Login
                            </Button>
                            <br />
                            <a href='/' style={{float: "right", fontSize:"15px"}}>
                                ย้อนกลับ
                            </a>
                            
                        </form>
                    </div>
                    <div className="pt-5 center" style={{ textAlign: "center" }}>
                        &copy; Siambanterng.com, {year}. All rights reserved.
                    </div>
                </div>
            </div>
        </div>
    )
}