
import React, { useState } from 'react';

export function Top10ByLatestTags() {

    const [listTags, setListTags] = useState([{}])

    React.useEffect(() => {
        getTags()
    }, [])

    async function getTags() {
        const res = await fetch("/api/news/getTop10Tag")
        const data = await res.json()
        // console.log(data)
        setListTags(data.dataenum)
    }


    return (
        <div className='p-2'>
                <div className='head-topic pb-2 mb-2'>
                    <a href="#nolink" className='notLink' onClick={ e => e.preventDefault() }>แท็กยอดนิยม</a>
                </div>
                <div className="tags-input-container" >
                    {listTags.map((item, index) => (
                        <a href={`tag/list?tname=${item.tagName}&page=1`} target="_blank" className="tags-link" rel="noreferrer" key={Number(index)}>
                            <div className="tag-item">
                                <span className="text">{item.tagName}</span>
                            </div>
                        </a>
                    ))}
                </div>
        </div>
    )
}