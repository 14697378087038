import { ManageUsers } from '../components/Admin/ManageUsers';

export function AdminManageUsers() {
    return (
        <>

            <ManageUsers />

        </>

    )
}