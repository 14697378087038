import React from 'react';
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';

export function Logout() {


    React.useEffect(() => {
        async function Gologout() {
            const MySwal = withReactContent(Swal)

            const items = JSON.parse(localStorage.getItem('currentUser'))

            if (items !== null) {
                let postData = new FormData()
                postData.append("token", items.token)

                let res = await fetch("/api/admin/logOut", {
                    method: 'POST',
                    body: postData
                });
                const user = await res.json();
                console.log(user);

                localStorage.removeItem('currentUser');
                MySwal.fire({
                    title: 'Logout success',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1800
                }).then((value) => {
                    window.location.href = "/";
                });
            } else {
                localStorage.removeItem('currentUser');
                MySwal.fire({
                    title: 'Logout success',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1800
                }).then((value) => {
                    window.location.href = "/";
                });
            }
        }
        Gologout()
    }, [])


    return (
        <></>
    )
}